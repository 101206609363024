<!--自定义选择框-->
<template>
  <template v-if="type === 1">
    <span style="display: inline-block;padding-right: 2px;color:#606266;font-size: 13px" >已开</span>
    <span style="display: inline-block;padding-left: 2px;"><el-checkbox v-model="yes" size="small" @change="v => {if(v && v === no) no = !v}">&nbsp;</el-checkbox></span>
    <span style="display: inline-block;padding-right: 2px;color:#606266; font-size: 13px" >未开</span>
    <span style="display: inline-block;padding-left: 2px;"><el-checkbox v-model="no" size="small"  @change="v => {if(v && v === yes) yes = !v}">&nbsp;</el-checkbox></span>
  </template>
  <template v-else-if="type === 2">
    <el-col :span="12" style="margin-top:-19px;">
      <el-form-item  style="border: 1px solid #999;text-align: right;" :label-width="160">
        <template #label>
          <span style="display: inline-block;padding-left: 2px;padding-left: 10px"><el-checkbox v-model="yes" @change="v => {if(v && v === no) no = !v}" size="small">&nbsp;</el-checkbox></span>
          <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >{{titles[0]}}</span>
        </template>
      </el-form-item>
    </el-col>
    <el-col :span="12" style="margin-top:-19px">
      <el-form-item  style="border: 1px solid #999;text-align: right;border-left-color: transparent" :label-width="160">
        <template #label>
          <span style="display: inline-block;padding-left: 2px;padding-left: 10px;"><el-checkbox v-model="no" @change="v => {if(v && v === yes) yes = !v}" size="small">&nbsp;</el-checkbox></span>
          <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >{{titles[1]}}</span>
        </template>
      </el-form-item>
    </el-col>
  </template>
  <template v-else-if="type === 3">
    <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >{{titles[0]}}<span style="color: transparent" v-if="!hiddenBorderRight">有</span></span>
    <span style="display: inline-block;padding-left: 10px;font-size: 13px"><el-checkbox v-model="yes" @change="v => {if(v && v === no) no = !v}" size="small">&nbsp;</el-checkbox></span>
    <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >{{titles[1]}}<span style="color: transparent" v-if="!hiddenBorderRight">有</span></span>
    <span style="display: inline-block;padding-left: 10px;border-right: 1px solid transparent;"><el-checkbox v-model="no" @change="v => {if(v && v === yes) yes = !v}" size="small">&nbsp;</el-checkbox></span>
  </template>
<!--  手机 -->
  <template v-else-if="type === 4">
    <el-col :span="12" style="margin-top:-19px;">
      <el-form-item  style="border: 1px solid #999;text-align: right;" :label-width="160">
        <template #label>
          <span style="display: inline-block;padding-left: 2px;padding-left: 10px"><el-checkbox v-model="yes" @change="v => {if(v && v === no) no = !v}" size="small">&nbsp;</el-checkbox></span>
          <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >{{titles[0]}}</span>
        </template>
      </el-form-item>
    </el-col>
    <el-col :span="12" style="margin-top:-19px">
      <el-form-item  style="border: 1px solid #999;text-align: right;border-left-color: transparent" :label-width="160">
        <template #label>
          <span style="display: inline-block;padding-left: 2px;padding-left: 10px;"><el-checkbox v-model="no" @change="v => {if(v && v === yes) yes = !v}" size="small">&nbsp;</el-checkbox></span>
          <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >{{titles[1]}}</span>
        </template>
      </el-form-item>
    </el-col>
  </template>
  <template v-else>
    <el-col :span="4">
      <span style="display: inline-block;padding: 1px 5px;color:#606266; border-right: 1px solid #999;font-size: 13px" >{{titles[0]}}</span>
      <span style="display: inline-block;padding: 1px 0px 1px 10px;border-right: 1px solid #999;font-size: 13px"><el-checkbox v-model="yes" size="small" @change="v => {if(v && v === no) no = !v}">&nbsp;</el-checkbox></span>
    </el-col>
    <el-col :span="4">
      <span style="display: inline-block;padding: 1px 5px;color:#606266; border-right: 1px solid #999;font-size: 13px" >{{titles[1]}}</span>
      <span style="display: inline-block;padding-left: 10px;padding-bottom: 2px;border-right: 1px solid #999; " :style="hiddenBorderRight ? {'border-right-color': 'transparent' } : ''"><el-checkbox v-model="no" size="small" @change="v => {if(v && v == yes ) yes = !v}">&nbsp;</el-checkbox></span>
    </el-col>
  </template>
</template>
<script setup>
import {ref, defineProps, watch, defineEmits} from "vue";
const emits = defineEmits(['changeValue']);
const props = defineProps(['mode', 'value', 'hiddenBorderRight', 'type', 'titles'])
const value = ref(props.value);
let yes = ref(value.value == 1 ), no = ref(value.value == 0);


watch([yes, no], () => {
  if (yes.value) emits('changeValue', 1)
  else if(no.value) emits('changeValue', 0)
  else emits('changeValue', -1)
})
</script>

<style scoped>

</style>