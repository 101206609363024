<template>
  <el-col :span="24">
    <div >
      <el-alert center title="注意:无开工单的项目一律不得开工！" type="error"   :closable="false"/>
      <el-alert center title="抢修工程7日内务必补单！" type="error"   :closable="false"/>
    </div>
  </el-col>
</template>

<script setup>

</script>

<style scoped>

</style>