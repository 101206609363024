<template>
  <p style="text-align: center;cursor: pointer;" @click="openRow" >
<!--    <el-text :type="showType ? applyStatus.type : ''">{{applyStatus.info}}</el-text>-->
    <img :src="applyStatus.src" v-if="applyStatus.src"  style="vertical-align: text-bottom"/>
    <el-text v-else :type="showType ? applyStatus.type : ''">{{applyStatus.info}}</el-text>
  </p>
</template>

<script setup>
import {defineProps} from "vue";
defineProps({
  applyStatus:{
    type: Object
  },
  showType:{
    type: Boolean,
    default: true
  }
})
//打开当前行
const openRow = (evt) => {
  evt.target.closest("tr").querySelector('i').click();
}
</script>

<style scoped>

</style>