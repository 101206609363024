<template>
  <el-form v-if="!isSmall || isPrint" :model="formData" label-width="120px" label-position="left"  id="applyForm" style="width:721px;" >
    <el-row >
      <el-col :span="24"  >
        <el-form-item  class="border" :label-width="'100%'">
          <template #label>
            <span style="display: inline-block;padding-left: 10px;margin: 0 auto;font-size: 15px;letter-spacing: 1px;font-weight: 600"> {{alter ? '工程变更单' : '工程开工单'}}</span>
          </template>
        </el-form-item>
      </el-col>
      <el-col :span="18"  style="margin-top: -19px;">
        <el-form-item   class="border" :label-width="90">
          <template #label>
            <span style="display: inline-block;border-right: 1px solid #999;padding-left: 10px;font-size: 13px"> 工程名称：</span>
          </template>
          <el-input v-model="formData.project_name" :placeholder="PR_NAME_PH" :readonly="alter || applyReadonly(applyMode, formData, permissions)"   maxlength="34" style="font-size: 13px;margin-left: -10px"      @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <el-col :span="6" style="margin-top: -19px">
        <el-form-item  class="border" style="border-left-color: transparent" :label-width="100" >
          <template #label>
            <span class="title" style="border-right: 1px solid #999;"> 工程编号：</span>
          </template>
          <span class="content" style="color: #606266;" v-if="formData.project_no">{{formData.project_no}}</span>
          <span class="content" style="color: #999;" v-else>提交自动生成</span>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top:-18px">
        <el-form-item  style="border-left: 1px solid #999;border-right: 1px solid #999;border-bottom: 1px solid #999" :label-width="90" >
          <template #label>
            <span class="title"> 甲方名称：</span>
          </template>
          <el-input v-model="formData.first_part" :readonly="alter || applyReadonly(applyMode, formData, permissions)" maxlength="48"   placeholder=""   style="font-size: 13px;margin-left: -10px;"    @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top:-18px">
        <el-form-item  style="border: 1px solid #999;border-top: none" :label-width="90">
          <template #label>
            <span class="title"> 项目名称：</span>
          </template>
          <el-input v-model="formData.project_item_name" :readonly="alter || applyReadonly(applyMode, formData, permissions)" maxlength="48" placeholder=""    style="font-size: 13px;margin-left: -10px;"   @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top:-18px;">
        <el-form-item  style="border-left: 1px solid #999;border-right: 1px solid #999" :label-width="90">
          <template #label>
            <span class="title"> 工程地点：</span>
          </template>
          <el-input v-model="formData.project_address" :readonly="alter || applyReadonly(applyMode, formData, permissions)" maxlength="48" placeholder=""     style="font-size: 13px;margin-left: -10px;"   @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <template v-if="!alter">
        <el-col :span="24" style="margin-top:-18px;position: relative">
          <div style="position: absolute;top: 0; left: 0; width: 100%; height: 70px; z-index: 999" v-if="applyReadonly(applyMode, formData, permissions)"></div>
          <el-form-item class="common-t-b"  style="border-bottom-color: transparent" :label-width="90">
            <template #label>
              <span class="title"> 工作范围：</span>
            </template>
            <el-col :span="4">
              <div style="width: 120px;border-left: 1px solid #999;float: right;">
                <span class="opt_title" >配电室</span>
                <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px"><el-checkbox  v-model="workRange['配电室']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="4">
              <span class="opt_title" >箱变</span>
              <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px"><el-checkbox v-model="workRange['箱变']" size="small">&nbsp;</el-checkbox></span>
            </el-col>
            <el-col :span="4">
              <span class="opt_title" >高压进线</span>
              <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px"><el-checkbox v-model="workRange['高压进线']" size="small">&nbsp;</el-checkbox></span>
            </el-col>
            <el-col :span="4">
              <span class="opt_title" >低压出线</span>
              <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px"><el-checkbox v-model="workRange['低压出线']" size="small">&nbsp;</el-checkbox></span>
            </el-col>
            <el-col :span="4">
              <span class="opt_title" >封闭母线</span>
              <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px"><el-checkbox v-model="workRange['封闭母线']" size="small">&nbsp;</el-checkbox></span>
            </el-col>
            <el-col :span="4">
              <span class="opt_title" >桥架</span>
              <span style="display: inline-block;padding-left: 10px;"><el-checkbox v-model="workRange['桥架']" size="small">&nbsp;</el-checkbox></span>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item class="common-t-b" style="border-bottom-color: transparent" :label-width="90">
            <template #label>
              <span style="display: inline-block;padding-left: 10px;font-size: 13px"> </span>
            </template>
            <el-col :span="4">
              <div style="width: 120px;border-left: 1px solid #999;float: right">
                <span style="display: inline-block;padding-right: 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;padding-top: 1px" >抢修</span>
                <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px;"><el-checkbox v-model="workRange['抢修']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="4">
              <span style="display: inline-block;padding-right: 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;padding-top: 1px" >台变</span>
              <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px;"><el-checkbox v-model="workRange['台变']" size="small" >&nbsp;</el-checkbox></span>
            </el-col>
            <el-col :span="4">
              <span style="display: inline-block;padding-right: 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;padding-top: 1px" >试验</span>
              <span style="display: inline-block;padding-left: 10px;border-right: 1px solid #999;padding-bottom: 1px;"><el-checkbox v-model="workRange['试验']"  size="small" >&nbsp;</el-checkbox></span>

            </el-col>
            <el-col :span="12">
              <el-form-item :label-width="70">
                <template #label>
                  <span class="title"> 其它：</span>
                </template>
                <el-input v-model="workRange['other']" maxlength="18"  placeholder=""    style="font-size: 13px;margin-left: -20px;"   @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;position: relative">
          <div style="position: absolute;top: 0; left: 0; width: 100%; height: 70px; z-index: 999" v-if="applyReadonly(applyMode, formData, permissions)"></div>
          <el-form-item  class="common-t-b" :label-width="90">
            <template #label>
              <span class="title"> 工程情况：</span>
            </template>
            <el-col :span="4">
              <div style="width: 120px;border-left: 1px solid #999;float: right;text-align: right">
                <span style="display: inline-block;padding:1px 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;" >中标通知书：</span>
              </div>
            </el-col>
            <ProjectCheck :mode="isSmall" @changeValue="v => formData.has_advice = v" :value="formData.has_advice" :titles="['有', '无']"></ProjectCheck>
            <el-col :span="4" >
              <div style="width: 120px;float: right;text-align: right;" >
                <span style="display: inline-block;padding: 1px 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;" >合同：</span>
              </div>
            </el-col>
            <ProjectCheck :mode="isSmall" @changeValue="v => formData.has_contract = v" :value="formData.has_contract" :hidden-border-right="true" :titles="['有', '无']"></ProjectCheck>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="90">
            <template #label>
              <span style="display: inline-block;padding-left: 5px;font-size: 13px"> </span>
            </template>
            <el-col :span="4">
              <div style="width: 120px;border-left: 1px solid #999;float: right;text-align: right">
                <span style="display: inline-block;padding: 1px 5px;color:#606266; border-right: 1px solid #999;font-size: 13px" >合同价款：</span>
              </div>
            </el-col>
            <ProjectCheck :mode="isSmall" @changeValue="v => formData.has_price = v" :value="formData.has_price" :titles="['已定', '未定']"></ProjectCheck>
            <el-col :span="12">
              <span style="display: inline-block;padding-right: 2px;color:#606266; font-size: 13px" >50万以上含施工的工程开工会：</span>
              <ProjectCheck :mode="isSmall" @changeValue="v => formData.has_meeting = v" :value="formData.has_meeting" :type="1"></ProjectCheck>
            </el-col>

          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-bottom-color: transparent" :label-width="86">
            <template #label>
              <span class="title"> 开工说明:</span>
            </template>
            <el-input    maxlength="282" :readonly="applyReadonly(applyMode, formData, permissions)" v-model="formData.description"  :rows="6" :autosize="{ minRows: 6, maxRows: 6 }"   style="font-size: 13px;margin-left: -20px;" type="textarea" placeholder='' @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-bottom-color: transparent" :label-width="110">
            <template #label>
              <span class="title"> 技术交底内容:</span>
            </template>
            <el-input   :rows="5" :readonly="applyReadonly(applyMode, formData, permissions)" v-model="formData.technical_content"   maxlength="225"  :autosize="{ minRows: 5, maxRows: 5 }"   style="font-size: 13px;margin-left: -20px;" type="textarea" :placeholder="applyMode ? '50万以上工程,以开工会内容为准。50万以内工程，自行填写交底内容。' : ''" @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
          </el-form-item>
        </el-col>
      </template>
      <template v-else>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-bottom-color: transparent" :label-width="110">
            <template #label>
              <span class="title"> 工程变更说明:</span>
            </template>
            <el-input   :rows="18" resize="none" :readonly="applyReadonly(applyMode, formData, permissions)" v-model="formData.technical_content"   maxlength="810"     style="font-size: 13px;margin-left: -20px;" type="textarea" :placeholder="applyMode ? '请填写工程变更说明' : ''" @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
          </el-form-item>
        </el-col>
      </template>

      <el-col :span="24" style="margin-top:-19px">
        <el-form-item  class="common-t-b" :label-width="60">
          <template #label>
            <span class="title"> 备注:</span>
          </template>
          <el-input   :readonly="applyReadonly(applyMode, formData, permissions)" :rows="2" v-model="formData.apply_remark"   maxlength="98" :autosize="{ minRows: 2, maxRows: 2 }"   style="font-size: 13px;margin-left: -20px;" type="textarea" placeholder='' @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <template v-if="!applyMode">
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="170">
            <template #label>
              <span class="title"> 工程申请人:</span> <span style="display: inline-block;padding-left: 10px;;font-size: 13px"> {{formData.applicant}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item class="common-t-b" style="border-left-color: transparent" :label-width="210">
            <template #label>
              <span class="title"> {{formData.update_time ? formData.update_time : ""}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  style="border: 1px solid #999;text-align: center;background-color: #9bc1e5" :label-width="721">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:#333; font-size: 13px;text-align: center;width: 100%;margin-left: 5px;letter-spacing: 2px" >工程部审核意见</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  class="common-t-b" :label-width="620">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:rgba(255,0,0,0.65); font-size: 13px" >{{NOTICE}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px;position: relative">
          <div style="position: absolute;left:0; top:0; z-index: 999;height: 105px;width: 100%" v-if="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10) "></div>
          <el-form-item  class="common-t-b" style="border-right-color: transparent" :label-width="200">
            <template #label>
              <span style="display: inline-block;padding-left: 10px;;font-size: 13px"> 是否与成本人员核实工作范围?</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px;position: relative">
          <div style="position: absolute;left:0; top:0; z-index: 999;height: 105px;width: 100%" v-if="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10) "></div>
          <el-form-item  class="common-t-b" style="border-left-color: transparent" :label-width="190">
            <template #label>
              <span class="title"> </span>
            </template>
          </el-form-item>
        </el-col>
        <ProjectCheck :mode="isSmall" @changeValue="v => formData.has_check = v" :value="formData.has_check" :type="2" :titles="['已核实工作范围', '未核实工作范围']"></ProjectCheck>
        <ProjectCheck :mode="isSmall" @changeValue="v => formData.project_agree = v" :value="formData.project_agree" :type="2" :titles="alter ? ['同意变更', '不同意变更'] : ['同意开工', '不同意开工']"></ProjectCheck>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  class="common-t-b" :label-width="90">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >驳回原因：</span>
            </template>
            <el-input   v-model="formData.project_reject_reason"  :readonly="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10)"   maxlength="48" style="font-size: 13px;margin-left: -15px;"  placeholder='' @blur="inputting = false; scrollTop()" @focus="inputting = true" :input-style="COL_OF_BH"/>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  class="common-t-b" :label-width="60">
            <template #label>
              <span style="display: inline-block;padding:0 5px;color:#606266; font-size: 13px" >备注:</span>
            </template>
            <el-input    v-model="formData.project_remark"   :readonly="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10)" maxlength="50" style="font-size: 13px;margin-left: -20px;"  placeholder='' @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="160">
            <template #label>
              <span class="title"> 工程部负责人:</span> <span style="display: inline-block;padding-left: 10px;;font-size: 13px">{{formData.project_agree_person}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-left-color: transparent" :label-width="210">
            <template #label>
              <span class="title">{{formData.project_examine_time}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;position: relative">
          <div style="position: absolute;left:0; top:0; z-index: 999;height: 70px;width: 100%" v-if="(!applyMode && formData.status !== 1) || (permissions & 0b100) !== 0b100"></div>
          <el-form-item  style="border: 1px solid #999;text-align: center;background-color: #f3af84" :label-width="721">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:#333; font-size: 13px;text-align: center;width: 100%;margin-left: 5px;letter-spacing: 2px" >最终审核意见</span>
            </template>
          </el-form-item>
        </el-col>
        <ProjectCheck :mode="isSmall" @changeValue="v => formData.project_approve = v" :value="formData.project_approve" :type="2" :titles="alter ? ['同意变更', '不同意变更'] : ['同意开工', '不同意开工']"></ProjectCheck>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  class="common-t-b" :label-width="90">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >驳回原因：</span>
            </template>
            <el-input   v-model="formData.project_approve_reject_reason"  :readonly="(!applyMode && formData.status !== 1) || (permissions & 0b100) !== 0b100" maxlength="48"  style="font-size: 13px;margin-left: -15px;"  placeholder='' @blur="inputting = false; scrollTop()" @focus="inputting = true" :input-style="COL_OF_BH"/>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="160">
            <template #label>
              <span style="display: inline-block;padding-left: 10px;;font-size: 13px"> 审批负责人:</span> <span style="display: inline-block;padding-left: 10px;;font-size: 13px">{{formData.project_approve_person}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-left-color: transparent" :label-width="210">
            <template #label>
              <span class="title">{{formData.project_approve_time}}</span>
            </template>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
  </el-form>
  <el-form v-else :model="formData" label-width="120px" label-position="left"  id="applyForm" style="width:100%;" >
    <el-row>
      <el-col :span="24"  >
        <el-form-item  class="border" :label-width="'100%'">
          <template #label>
            <span style="display: inline-block;padding-left: 10px;margin: 0 auto;font-size: 13px"> {{alter ? '工程变更单' : '工程开工单'}}</span>
          </template>
        </el-form-item>
      </el-col>
      <el-col :span="24"  style="margin-top: -19px;">
        <el-form-item  class="border" :label-width="100">
          <template #label>
            <span style="display: inline-block;border-right: 1px solid #999;padding-left: 10px;font-size: 13px;height: 90px"> 工程名称：</span>
          </template>
          <el-input v-model="formData.project_name" :placeholder="PR_NAME_PH" :readonly="alter || applyReadonly(applyMode, formData, permissions)"  :autosize="{ minRows: 3, maxRows: 4 }"   maxlength="34"   style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true" />
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top: -19px;">
        <el-form-item  class="border" :label-width="100" >
          <template #label>
            <span class="title" style="border-right: 1px solid #999;"> 工程编号：</span>
          </template>
          <span class="content" style="color: #606266;" v-if="formData.project_no">{{formData.project_no}}</span>
          <span class="content" style="color: #999;" v-else>提交自动生成</span>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top:-18px">
        <el-form-item  style="border-left: 1px solid #999;border-right: 1px solid #999;border-bottom: 1px solid #999" :label-width="100">
          <template #label>
            <span class="title"> 甲方名称：</span>
          </template>
          <el-input v-model="formData.first_part" :readonly="alter || applyReadonly(applyMode, formData, permissions)"  :autosize="{ minRows: 1, maxRows: 5 }"   maxlength="48"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top:-18px">
        <el-form-item  style="border-left: 1px solid #999;border-right: 1px solid #999;border-bottom: 1px solid #999" :label-width="100">
          <template #label>
            <span class="title"> 项目名称：</span>
          </template>
          <el-input v-model="formData.project_item_name" :readonly="alter || applyReadonly(applyMode, formData, permissions)"  placeholder="" :autosize="{ minRows: 1, maxRows: 5 }"   maxlength="48"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top:-18px;">
        <el-form-item  style="border-left: 1px solid #999;border-right: 1px solid #999;" :label-width="100">
          <template #label>
            <span class="title"> 工程地点：</span>
          </template>
          <el-input v-model="formData.project_address" :readonly="alter || applyReadonly(applyMode, formData, permissions)"  placeholder="" :autosize="{ minRows: 1, maxRows: 5 }"   maxlength="48"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>

      <template v-if="!alter">
        <el-col :span="24" style="margin-top:-18px;position: relative">
          <div style="position: absolute;top: 0; left: 0; width: 100%; height: 300px; z-index: 999" v-if="applyReadonly(applyMode, formData, permissions)"></div>
          <el-form-item  class="common-t-b" style="border-bottom-color: transparent" :label-width="100">
            <template #label>
              <span class="title"> 工作范围：</span>
            </template>
            <el-col :span="24" style="margin-left: -20px;">
              <div style="width: 120px;border-left: 1px solid #999;">
                <span class="opt_title" >配电室</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px"><el-checkbox v-model="workRange['配电室']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24" style="margin-left: -20px;">
              <div style="width: 120px;border-left: 1px solid #999;">
                <span class="opt_title" >箱变</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px"><el-checkbox v-model="workRange['箱变']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 120px;border-left: 1px solid #999;margin-left: -20px;">
                <span class="opt_title" >高压进线</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px"><el-checkbox v-model="workRange['高压进线']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 120px;border-left: 1px solid #999;margin-left: -20px;">
                <span class="opt_title" >低压出线</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px"><el-checkbox v-model="workRange['低压出线']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 120px;border-left: 1px solid #999;margin-left: -20px;">
                <span class="opt_title" >封闭母线</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px"><el-checkbox v-model="workRange['封闭母线']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 120px;border-left: 1px solid #999;margin-left: -20px;">
                <span class="opt_title" >桥架</span>
                <span style="display: inline-block;padding-left: 10px;"><el-checkbox v-model="workRange['桥架']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-bottom-color: transparent;border-top-color: transparent" :label-width="100">
            <template #label>
              <span class="title" style="color: transparent;">工作范围:</span>
            </template>
            <el-col :span="24" style="margin-left: -20px;">
              <div style="width: 120px;border-left: 1px solid #999;">
                <span class="opt_title" >抢修</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px;"><el-checkbox v-model="workRange['抢修']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 120px;border-left: 1px solid #999;margin-left: -20px;">
                <span class="opt_title" >台变</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px;"><el-checkbox v-model="workRange['台变']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 120px;border-left: 1px solid #999;margin-left: -20px;">
                <span class="opt_title" >试验</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px;"><el-checkbox v-model="workRange['试验']" size="small">&nbsp;</el-checkbox></span>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 120px;border-left: 1px solid #999;position: relative;margin-left: -20px;">
                <span style="display: inline-block;padding-right: 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;padding-top: 1px;height: 70px" >其它</span>
                <span style="display: inline-block;padding-left: 10px;padding-bottom: 1px;visibility:hidden"><el-checkbox :label="3" size="small">&nbsp;</el-checkbox></span>
                <el-input v-model="workRange['other']" :readonly="applyReadonly(applyMode, formData, permissions)" placeholder="" :autosize="{ minRows: 1, maxRows: 3 }"   maxlength="18"  style="font-size: 13px;margin-left: -20px;position: absolute;top: 2px;width: 120px;margin-top: -1px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
              </div>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;position: relative">
          <div style="position: absolute;top: 0; left: 0; width: 100%; height: 160px; z-index: 999" v-if="applyReadonly(applyMode, formData, permissions)"></div>

          <el-form-item  class="common-t-b" style="border-bottom: none" :label-width="100">
            <template #label>
              <span class="title">工程情况:</span>
            </template>
            <div style="width: 100%">
              <el-col :span="24" style=";">
                <div style="width: 226px;border-left: 1px solid #999;margin-left: -20px">
                  <span style="display: inline-block;padding-right: 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;" >中标通知书</span>
                  <ProjectCheck :mode="isSmall" :titles="['有', '无']" @changeValue="v => formData.has_advice = v" :type="3" :value="formData.has_advice"></ProjectCheck>
                </div>
              </el-col>
            </div>
            <el-col :span="24" >
              <div style="width: 226px;border-left: 1px solid #999;margin-left: -20px">
                <span style="display: inline-block;padding-right: 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;padding-top: 1px" >合同</span>
                <ProjectCheck :mode="isSmall" :titles="['有', '无']" @changeValue="v => formData.has_contract = v" :type="3" :value="formData.has_contract"></ProjectCheck>
              </div>
            </el-col>
            <el-col :span="24" >
              <div style="width: 226px;border-left: 1px solid #999;margin-left: -20px">
                <span style="display: inline-block;padding-right: 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;padding-top: 1px" >合同价款</span>
                <ProjectCheck :mode="isSmall" :titles="['已定', '未定']" @changeValue="v => formData.has_price = v" :type="3" :value="formData.has_price" :hidden-border-right="true"></ProjectCheck>
              </div>
            </el-col>
            <el-col :span="24">
              <div style="width: 226px;border-left: 1px solid #999;margin-left: -20px;">
                <span style="display: inline-block;padding: 0 5px;color:#606266; border-right: 1px solid #999;font-size: 13px;width: 70px;line-height: 18px;height: 54px" >50万以上含施工的工程开工会</span>
                <div style="display: inline-block;">
                  <ProjectCheck :mode="isSmall" :titles="['已开', '未开']" @changeValue="v => formData.has_meeting = v" :type="3" :value="formData.has_meeting" :hidden-border-right="true"></ProjectCheck>
                </div>
              </div>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="86">
            <template #label>
              <span class="title">开工说明:</span>
            </template>
            <el-input v-model="formData.description" :readonly="applyReadonly(applyMode, formData, permissions)" placeholder="" :autosize="{ minRows: 1, maxRows: 21 }"   maxlength="282"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="112">
            <template #label>
              <span class="title">技术交底内容:</span>
            </template>
            <el-input v-model="formData.technical_content" :readonly="applyReadonly(applyMode, formData, permissions)" :placeholder="applyMode ? '50万以上工程,以开工会内容为准。50万以内工程，自行填写交底内容。' : ''" :autosize="{ minRows: 2, maxRows: 20 }"   maxlength="225"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
          </el-form-item>
        </el-col>
      </template>

      <template v-else>
        <el-col :span="24" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-bottom-color: transparent" :label-width="110">
            <template #label>
              <span class="title"> 工程变更说明:</span>
            </template>
            <el-input   :rows="50" :readonly="applyReadonly(applyMode, formData, permissions)" v-model="formData.technical_content"   maxlength="225"  :autosize="{ minRows: 5, maxRows: 50 }"   style="font-size: 13px;margin-left: -20px;" type="textarea" :placeholder="applyMode ? '请填写工程变更说明' : ''" @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
          </el-form-item>
        </el-col>
      </template>




      <el-col :span="24" style="margin-top:-19px">
        <el-form-item  class="common-t-b" :label-width="60">
          <template #label>
            <span class="title">备注:</span>
          </template>
          <el-input v-model="formData.apply_remark" placeholder="" :readonly="applyReadonly(applyMode, formData, permissions)" :autosize="{ minRows: 1, maxRows: 8 }"   maxlength="98"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
        </el-form-item>
      </el-col>
      <template v-if="!applyMode">
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  style="border: 1px solid #999;text-align: right" :label-width="166">
            <template #label>
              <span class="title">工程申请人:</span> <span style="display: inline-block;padding-left: 10px;;font-size: 13px"> {{formData.applicant}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-left-color: transparent" :label-width="190">
            <template #label>
              <span class="title"> {{formData.update_time ? formData.update_time.split(" ")[0] : ""}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  style="border: 1px solid #999;text-align: center;background-color: #9bc1e5" :label-width="-1">
            <p style="min-height:34px;line-height: 34px;padding: 0 5px;color:#333; font-size: 13px;text-align: center;width: 100%;margin-left: 5px;letter-spacing: 2px;" >工程部审核意见</p>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;">
          <div style="min-height: 40px;line-height: 20px;text-align: left;padding: 6px 0 19px;border-left: 1px solid #999; border-right: 1px solid #999;">
            <span style="display: inline-block;padding: 0 5px;color:rgba(255,0,0,0.65); font-size: 13px;" >{{NOTICE}}</span>
          </div>
        </el-col>
        <el-col :span="12" style="margin-top:-19px;position: relative">
          <div style="position: absolute;left:0; top:0; z-index: 999;height: 100px;width: 100%" v-if="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10) "></div>
          <el-form-item class="common-t-b" style="border-right-color: transparent" :label-width="200">
            <template #label>
              <span class="title"> 是否与成本人员核实工作范围?</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px;position: relative">
          <div style="position: absolute;left:0; top:0; z-index: 999;height: 100px;width: 100%" v-if="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10) "></div>
          <el-form-item class="common-t-b" style="border-left-color: transparent" :label-width="190">
            <template #label>
              <span class="title"> </span>
            </template>
          </el-form-item>
        </el-col>
        <ProjectCheck :mode="isSmall" @changeValue="v => formData.has_check = v" :value="formData.has_check" :type="4" :titles="['已核实工作范围', '未核实工作范围']"></ProjectCheck>
        <ProjectCheck :mode="isSmall" @changeValue="v => formData.project_agree = v" :value="formData.project_agree" :type="4" :titles="alter ? ['同意变更', '不同意变更'] : ['同意开工', '不同意开工']"></ProjectCheck>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  class="common-t-b" :label-width="90">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >驳回原因：</span>
            </template>
            <el-input v-model="formData.project_reject_reason" :readonly="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10)" placeholder="" :autosize="{ minRows: 1, maxRows: 5 }"   maxlength="48"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true" :input-style="COL_OF_BH"/>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  class="common-t-b" :label-width="90">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >备注：</span>
            </template>
            <el-input v-model="formData.project_remark" :readonly="(!applyMode && formData.status !== 0) || ((permissions & 0b10) !== 0b10)" placeholder="" :autosize="{ minRows: 1, maxRows: 5 }"   maxlength="50"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="160">
            <template #label>
              <span class="title"> 工程部负责人:</span> <span style="display: inline-block;padding-left: 10px;font-size: 13px"> {{formData.project_agree_person}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-left-color: transparent" :label-width="190">
            <template #label>
              <span class="title"> {{formData.project_examine_time ? formData.project_examine_time.split(" ")[0] : ""}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-top:-19px;position: relative">
          <div style="position: absolute;left:0; top:0; z-index: 999;height: 70px;width: 100%" v-if="(!applyMode && formData.status !== 1) || (permissions & 0b100) !== 0b100"></div>
          <el-form-item  style="border: 1px solid #999;background-color: #f3af84" :label-width="-1">
            <p style="min-height:34px;line-height: 34px;padding: 0 5px;color:#333; font-size: 13px;text-align: center;width: 100%;margin-left: 5px;letter-spacing: 2px;" >最终审核意见</p>
          </el-form-item>
        </el-col>
        <ProjectCheck :mode="isSmall" style="" @changeValue="v => formData.project_approve = v" :value="formData.project_approve" :type="4" :titles="alter ? ['同意变更', '不同意变更'] : ['同意开工', '不同意开工']"></ProjectCheck>
        <el-col :span="24" style="margin-top:-19px;">
          <el-form-item  class="common-t-b" :label-width="90">
            <template #label>
              <span style="display: inline-block;padding: 0 5px;color:#606266; font-size: 13px" >驳回原因：</span>
            </template>
            <el-input v-model="formData.project_approve_reject_reason" :readonly="(!applyMode && formData.status !== 1) || (permissions & 0b100) !== 0b100" placeholder="" :autosize="{ minRows: 1, maxRows: 5 }"   maxlength="48"  style="font-size: 13px;margin-left: -20px;" type="textarea"       @blur="inputting = false; scrollTop()"  @focus="inputting = true" :input-style="COL_OF_BH"/>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" :label-width="160">
            <template #label>
              <span class="title"> 审批负责人:</span> <span style="display: inline-block;padding-left: 10px;;font-size: 13px"> {{formData.project_approve_person}}</span>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top:-19px">
          <el-form-item  class="common-t-b" style="border-left-color: transparent" :label-width="190">
            <template #label>
              <span class="title"> {{formData.project_approve_time ? formData.project_approve_time.split(" ")[0] : ""}}</span>
            </template>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
  </el-form>
  <div class="apply-btn" style="margin: 0 auto" :style="isSmall ? {'width': '100%'} : {'width': '721px'}" v-if="!isLog">
    <el-divider v-if="!applyMode && formData.status == 3">
      <span style="color: #FF0000;letter-spacing: 2px">{{alter ? '变更单' : '开工单'}}已作废</span>
    </el-divider>
    <el-row gutter="8">
      <el-col :span="(!applyMode && formData.status == 2 && !alter) ? 12 : 24">
        <el-button type="primary" style="width: 100%" @click="$emit('apply', formData)" :disabled="formData.status == 2 || formData.status == 3">提交</el-button>
      </el-col>
      <el-col :span="12" v-if="!applyMode && formData.status == 2 && !alter">
        <el-button type="success" style="width: 100%;" @click="$emit('applyAlter', formData)" >为本工程追加-变更单</el-button>
      </el-col>
    </el-row>
    <template v-if="!applyMode && formData.alter_orders ">
      <el-button type="info" style="width: 100%;margin-top: 10px;letter-spacing: 2px" @click="$emit('print', formData)" >打印{{(formData.count == 0 && formData.alter_orders && formData.alter_orders.length > 1 ) ? '开工单及变更单' : ''}}</el-button>
      <br/>
    </template>

    <el-button type="warning" style="width: 100%;margin-top: 10px;letter-spacing: 2px" @click="$emit('apply', formData)" v-if="!applyMode && (formData.status == 2 || formData.status == 3) &&  (permissions & 0b1000) === 0b1000">
      <span>修改完内容后，点击此处提交</span>
    </el-button>
    <br/>
    <el-button type="danger" style="width: 100%;margin-top: 10px;letter-spacing: 2px" @click="$emit('apply', formData, 3)" :disabled="formData.status == 3" v-if="!applyMode && (formData.status == 2 || formData.status == 3) &&  (permissions & 0b1000) === 0b1000">
      <span v-if="alter">{{formData.status == 3 ? '变更单已作废' : '作废变更单'}}</span>
      <span v-else>{{formData.status == 3 ? '开工单已作废' : '作废开工单'}}</span>
    </el-button>
  </div>
</template>

<script setup>
import {defineProps, reactive, defineEmits, computed, watch} from "vue";
import {useFormTop} from "@/assets/js/formTop";
import {useSize} from "@/assets/js/size";
import ProjectCheck from "@/components/ProjectCheck";
defineEmits(['apply', 'print', 'applyAlter'])
const props = defineProps(['formData', 'readOnly', 'applyMode', 'permissions', 'isPrint', 'alter', 'isLog'])
const formData = reactive(props.formData);
const PR_NAME_PH = '工程名称以合同或图纸上的名称为准，不准简写，以防与其它工程混淆'
const NOTICE = "注意：签字前，请确认并安排方案答复、图纸设计及设计变更、设备异动、停电计划、设备试验等事宜。";
const COL_OF_BH = {'color': '#ff4500'}
const {inputting, scrollTop} = useFormTop();
let {width} = useSize();
const isSmall = computed(() => width.value < 967);
const ITEMS = "配电室|箱变|高压进线|低压出线|封闭母线|桥架|抢修|台变|试验";
const wr = formData.work_range.split("|");
const workRange = reactive({
    "配电室": wr.includes("配电室"),
    "箱变": wr.includes("箱变"),
    "高压进线": wr.includes("高压进线"),
    "低压出线": wr.includes("低压出线"),
    "封闭母线": wr.includes("封闭母线"),
    "桥架": wr.includes("桥架"),
    "抢修": wr.includes("抢修"),
    "台变":wr.includes("台变"),
    "试验": wr.includes("试验"),
    "other": wr.length > 0 ? (wr.find(v => ITEMS.indexOf(v) === -1) ? wr[wr.length - 1] : "") : ""
});

watch(workRange, newValue => {
  const items = [];
  for (const [k, v] of Object.entries(newValue)){
    if ((typeof v === 'boolean') && v) items.push(k);
    else if (v) items.push(v);
  }
  formData.work_range = items.join("|");
});

const applyReadonly = (applyMode, formData, permissions) => (formData.status !== 2 && formData.status !== 3) ? (!applyMode && formData.status !== -1) : ((permissions & 0b1000) !== 0b1000);


</script>

<style >
#applyForm{
  margin: 0 auto;
  font-size: 13px;
}
#applyForm .border {
  border: 1px solid #999;
}
#applyForm span.content{
  display: inline-block;
  font-size: 13px;
  margin-left: -16px;
  width: 90px;
  text-align: center;
}
#applyForm .common-t-b{
  border: 1px solid #999;
  text-align: right;
}
#applyForm span.title{
  display: inline-block;
  padding-left: 10px;
  font-size: 13px;
}
#applyForm span.opt_title{
  display: inline-block;
  padding-right: 5px ;
  color:#606266;
  border-right: 1px solid #999;
  font-size: 13px;
  padding-top: 1px;
}
#applyForm div.el-input__wrapper, #applyForm .el-textarea__inner{
  box-shadow: none !important;
}
#applyForm textarea{
  resize: none;
}
#applyForm span.el-checkbox__inner{
  border-color: #606266;
}
#applyForm div.el-input__wrapper{
  padding: 0 !important;
}
@media print {
  .apply-btn{
    display: none !important;
  }
  #applyForm span.el-checkbox__inner{
    background-color: transparent !important;
  }
  #applyForm span.el-checkbox__input.is-checked, #applyForm span.el-checkbox__inner::after{
    border-color: #000 !important;
    background-color: transparent !important;
  }
}
</style>